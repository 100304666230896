module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Allowa | Vente","short_name":"Allowa | Vente","start_url":"/","background_color":"#f7f0eb","theme_color":"#0B399A","display":"standalone","icon":"src/assets/allowa-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d4d186069d6bf2648bcacc3460391c08"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"3fd89711-6334-4380-979c-c0ed4ec7b70d","enableDuringDevelop":false,"defer":true,"enableImprovedAccessibility":false},
    }]
